<template>
	<div>
		<div class="container-md">
			<terminal v-model="terminal" :error-message="errorMessage" class="mb-50"></terminal>

			<div class="flex align-start">
				<a href="#" class="btn" @click.prevent="saveTerminal()">Stovna</a>
			</div>
		</div>
	</div>
</template>

<script>
import Terminal from '@/components/forms/Terminal.vue';
import axios from 'axios';

export default {
	name: 'TerminalCreate',

	components: {
		Terminal,
	},

	data() {
		return {
			terminal: {},
			errorMessage: '',
		};
	},

	methods: {
		saveTerminal() {
			axios
				.post('/terminals', this.terminal)
				.then(() => {
					this.$router.push({ name: 'Terminals' });
				})
				.catch((error) => {
					console.log('Error saving terminal', error);

					this.errorMessage = error.response?.data?.message;

					setTimeout(() => {
						this.errorMessage = '';
					}, 4000);
				});
		},
	},
};
</script>
